import moment from "moment";
import constants from "./constants";
import Cookies from "js-cookie";
import swal from "sweetalert";
import strings from "../locals/string.json";
import routes from "./routes";

const modals = strings.modals;

export function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

export const getB64ToBlob = (b64Data, contentType = 'image/png') => {
    const byteCharacters = atob(b64Data.substr(`data:${contentType};base64,`.length));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    window.open(URL.createObjectURL(blob), '_blank');
};

export const onSubCategoryChange = (category, item, data, cat) => {
    if (!(data.filter(e => e.id === item.id).length > 0)) {
        data.push(item);
    } else {
        data.splice(data.findIndex(e => e.id === item.id), 1);
    }

    if (!(cat.filter(e => e.id === category.id).length > 0)) {
        cat.push(category);
    }

    return {data, cat}
}

export const onCategoryChange = (category, data, cat) => {
    category.subCategory.map(item => {
        if ((data.filter(e => e.id === item.id).length > 0)) {
            data.splice(data.findIndex(e => e.id === item.id), 1);
        }
        return true;
    })
    if ((cat.filter(e => e.id === category.id).length > 0)) {
        cat.splice(cat.findIndex(e => e.id === category.id), 1);
    }
    return {data, cat}
}

export const handleSubCategoryChange = (index, status, id, data) => {
    const arr = []
    data.subCategory.map(sub => {
        if (id !== undefined) {
            if (sub.id === id) {
                arr.push({
                    ...sub,
                    checked: status
                })
            } else {
                arr.push(sub)
            }
        } else {
            arr.push({
                ...sub,
                checked: status
            })
        }
    })
    return {
        ...data,
        subCategory: arr
    }
}

export const dateDiff = (item) => {
    return moment(item.closingDate).local().diff(moment().local(), 'days');
}

export const removeCookiesValues = async () => {
    await Cookies.remove(constants.ACCESS_TOKEN);
    await Cookies.remove(constants.REFRESH_TOKEN);
    await localStorage.removeItem(constants.ACCESS_TOKEN);
    await localStorage.removeItem(constants.USER_ORIGINAL_OBJ);
    await localStorage.removeItem(constants.USER_OBJ);
};

export const createUser = (data) => {
    if (data === undefined) {
        removeCookiesValues()
        window.location.href = routes.login
    }
    return {
        id: data.account.id ?? null,
        firstName: data.firstName ?? null,
        lastName: data.lastName ?? null,
        email: data.email ?? null,
        userType: constants.USER_SELLER,
        companyName: data.account.companyName ?? null,
        designation: data.account.designation ?? null,
        landNumber: data.account.landNumber ?? null,
        mobileNumber: data.mobileNumber ?? null,
        hotLine: data.account.hotLine ?? null,
        categoryCount: data.account.categoryCount ?? null,
        website: data.account.website ?? null,
        profilePicture: data.account.profilePicture ?? null,
        billingProof: data.account.billingProof ?? null,
        companyUrl: data.account.companyUrl ?? null,
        address: data.account.address ?? null,
        coverImage: data.account.coverImage ?? null,
        ratingValue: data.account.ratingValue ?? null,
        ratingCount: data.account.ratingCount ?? null,
        location: data.account.location ?? null,
        secondaryEmails: data.account.secondaryEmails ?? null,
        description: data.account.description ?? null,
        categories: data.account.categories ?? null,
        accountManager: data.account.accountManager ?? null,
        expiryDate: data.account.expiryDate ?? null,
        emailCount: data.account.emailCount ?? null,
    }
}

export const createUserRegDetails = state => {
    let formData = new FormData();
    formData.append("firstName", state.firstName);
    formData.append("lastName", state.lastName);
    formData.append("email", state.email);
    formData.append("password", state.password);
    formData.append("mobileNumber", state.mobile);
    formData.append("packageId", state.packageId);
    formData.append("timeDiff", state.diff);


    state.location && formData.append("location", state.location);

    state.companyName !== '' && formData.append("companyName", state.companyName);
    state.companyUrl !== '' && formData.append("companyUrl", state.companyUrl);
    state.designation !== '' && formData.append("designation", state.designation);
    state.website !== '' && formData.append("website", state.website);
    state.hotline !== '' && formData.append("hotLine", state.hotline);
    state.address !== '' && formData.append("address", state.address);
    state.land !== '' && formData.append("landNumber", state.land);
    state.coverImageFile && formData.append("coverImage", state.coverImageFile);
    state.imageFile && formData.append("profilePicture", state.imageFile);

    let secondaryEmails = '';
    state.secondaryEmails.map((item, index) => {
        secondaryEmails += (index !== 0 ? "," : "") + item
    })

    secondaryEmails !== '' && formData.append("secondaryEmail", secondaryEmails);
    state.subCategory.length !== 0 && formData.append("userSubCategoriesString", JSON.stringify(state.subCategory));
    state.billingProofFile && formData.append("billingProof", state.billingProofFile);
    formData.append("type", state.userType);
    return formData;
}

export const createETUserRegDetails = state => {
    let formData = new FormData();
    formData.append("firstName", state.firstName);
    formData.append("lastName", state.lastName);
    formData.append("email", state.email);
    formData.append("password", state.password);
    formData.append("mobileNumber", state.mobile);

    state.companyName !== null && formData.append("companyName", state.companyName);
    state.companyUrl !== null && formData.append("companyUrl", state.companyUrl);
    state.designation !== null && formData.append("designation", state.designation);
    state.website !== null && formData.append("website", state.website);
    state.address !== null && formData.append("address", state.address);
    state.location !== null && formData.append("location", state.location);
    state.landNumber !== null && formData.append("landNumber", state.landNumber);
    state.hotLine !== null && formData.append("hotLine", state.hotLine);

    state.secondaryEmail !== null && formData.append("secondaryEmail", state.secondaryEmail);
    formData.append("type", constants.USER_SELLER);
    return formData;
}

export const createPurchaseRequestDetails = state => {
    let formData = new FormData();
    state.id && formData.append("id", state.id);
    formData.append("topic", state.title);
    formData.append("description", state.description);
    formData.append("qty", state.quantity);
    formData.append("closingDate", moment(state.closingDate).format(constants.DATE_FORMAT));
    formData.append("prefferedContactMethod", state.preferredContactMethod);
    formData.append("urgent", state.urgent);
    formData.append("subCategoryString", JSON.stringify(state.subCategory));
    formData.append("unit", '0');
    state.user && formData.append("userString", JSON.stringify(state.user));

    formData.append("estimatedValue", state.estimatedValue === '' ? 0 : state.estimatedValue);
    (state.district && state.district !== '') && formData.append("location", state.district);
    state.attachmentFile && formData.append("attachment", state.attachmentFile);
    return formData;
}

export const createQuoteDetails = state => {
    let formData = new FormData();
    formData.append("requestId", state.id);
    formData.append("quotePrice", state.quotePrice);
    formData.append("quoteDescription", state.description);
    state.attachmentFile && formData.append("quotePdf", state.attachmentFile);
    return formData;
}

export const createUpdateQuoteDetails = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    formData.append("quotePrice", state.quotePrice);
    formData.append("quoteDescription", state.description);
    state.attachmentFile && formData.append("quotePdf", state.attachmentFile);
    return formData;
}

export const createProduct = (state, update) => {
    let formData = new FormData();
    state.id && formData.append("id", state.id);
    formData.append("productName", state.productName);
    formData.append("description", state.description);
    formData.append("unitPrice", state.unitPrice);
    formData.append("subCategory", state.subCategory);
    state.availability && formData.append("availability", state.availability);
    if (update) {
        formData.append("category", state.category.id.toString());
    } else {
        formData.append("category", state.selectedCategory.id.toString());
    }

    let att = '';
    state.productAttributes.map((item, index) => {
        att += (index !== 0 ? "," : "") + item.key + ":" + item.value
    })

    formData.append("productAttributes", att);
    state.imageFile && formData.append("image", state.imageFile);
    return formData;
}

export const checkLoginStatus = () => {
    if (localStorage.getItem(constants.ACCESS_TOKEN) === null) {
        swal({
            title: modals.sorry,
            text: modals.loginWarning,
            closeOnClickOutside: false,
            buttons: {
                success: {text: "Ok", value: "action", className: "swalSuccess"},
                dangerMood: {text: "Cancel", value: "cancel", className: "swalCancel"}
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        window.location = routes.login
                        break;
                    default:
                        break;
                }
            });
        return false;
    } else return true
}

export const checkAvailability = (permission) => {
    swal({
        title: modals.sorry,
        text: permission ? modals.categorySubscribe : modals.becomeASeller,
        closeOnClickOutside: false,
        buttons: {
            success: {text: "Ok", value: "action", className: "swalSuccess"},
            dangerMood: !permission ? {text: "Cancel", value: "cancel", className: "swalCancel"} : null
        }
    })
        .then((value) => {
            switch (value) {
                case "action":
                    if (!permission) window.location = routes.login
                    break;
                default:
                    break;
            }
        });
}

export const checkAvailabilityForTenderView = (permission) => {
    swal({
        title: modals.sorry,
        text: permission ? modals.categorySubscribe : modals.becomeASeller,
        closeOnClickOutside: false,
        buttons: {
            success: {text: "Subscribe", value: "action", className: "swalSuccess"},
            dangerMood: !permission ? {text: "Cancel", value: "cancel", className: "swalCancel"} : null
        }
    })
        .then((value) => {
            switch (value) {
                case "action":
                    if (!permission) window.location = routes.register
                    break;
                default:
                    break;
            }
        });
}

export const createUpdateUser = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    state.imageFile && formData.append("profilePicture", state.imageFile);
    state.firstName.trim() !== '' && formData.append("firstName", state.firstName);
    state.lastName.trim() !== '' && formData.append("lastName", state.lastName);
    state.hotLine.trim() !== '' && formData.append("hotLine", state.hotLine);
    state.landNumber.trim() !== '' && formData.append("landNumber", state.landNumber);
    state.address.trim() !== '' && formData.append("address", state.address);
    state.location !== null && formData.append("location", state.location);
    state.companyName.trim() !== '' && formData.append("companyName", state.companyName);
    state.designation.trim() !== '' && formData.append("designation", state.designation);
    state.website.trim() !== '' && formData.append("website", state.website);
    state.companyUrl.trim() !== '' && formData.append("companyUrl", state.companyUrl);
    state.coverImageFile && formData.append("coverImage", state.coverImageFile);
    state.mobile.trim() !== '' && formData.append("mobileNumber", state.mobile);
    state.billingProofFile && formData.append("billingProof", state.billingProofFile);
    // state.description.trim() !== '' && formData.append("description", state.description);

    let secondaryEmails = '';
    state.secondaryEmails.map((item, index) => {
        secondaryEmails += (index !== 0 ? "," : "") + item
    })

    secondaryEmails.trim() !== '' && formData.append("secondaryEmails", secondaryEmails);
    return formData;
}

export const createUpdateUserCategory = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    formData.append("subCategoriesString", JSON.stringify(state.subCategory));
    return formData;
}

export const createUpdateUserDescription = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    formData.append("description", state.editorHtml.toString());
    return formData;
}

export const getBase64 = (file) => {
    if (file.endsWith('.pdf')) {
        fetch(file).then(resp => resp.arrayBuffer()).then(resp => {
            const file = new Blob([resp], {type: 'application/pdf'});
            document.getElementById('downloadbtn').href = URL.createObjectURL(file);
        });
    } else if (file.endsWith('.doc') || file.endsWith('.docx')) {
        // console.log(file)
        fetch(file).then(resp => resp.arrayBuffer()).then(resp => {
            const file = new Blob([resp], {type: 'application/msword'});
            // console.log(file)
            document.getElementById('downloadbtn').href = URL.createObjectURL(file);
        });
    } else {
        let image = new Image();
        image.crossOrigin = "anonymous";
        image.src = file;
        image.onload = function () {
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            canvas.getContext('2d').drawImage(this, 0, 0);
            let blob;
            if (image.src.indexOf(".jpg") > -1) {
                blob = canvas.toDataURL("image/jpeg");
            } else if (image.src.indexOf(".png") > -1) {
                blob = canvas.toDataURL("image/png");
            } else if (image.src.indexOf(".gif") > -1) {
                blob = canvas.toDataURL("image/gif");
            } else {
                blob = canvas.toDataURL("image/png");
            }

            if (blob !== null) {
                document.getElementById('downloadbtn').href = blob;
            }
        };
    }
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const openWebSite = (web) => {
    if (!web.startsWith('https://')) web = 'https://' + web;
    window.open(web, '_blank')
}

export async function getConvertedUTCDateTIme(dateStart, dateEnd) {
    if (dateStart !== null && dateEnd !== null) {
        let pub = (new Date((dateStart).format().toString().split('T')[0] + " 00:00:00").toISOString()).split('.')[0] + "Z";
        let ex = (new Date((dateEnd).format().toString().split('T')[0] + " 23:59:59").toISOString()).split('.')[0] + "Z";
        return {
            dateStart: pub,
            dateEnd: ex
        }
    }
    return null
}

export const getTimeDifferenceWithUTC = () => {
    let timezoneOffset = new Date().getTimezoneOffset();
    let hours = (Math.abs(timezoneOffset) / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    if (timezoneOffset < 0) {
        let time_difference = "+" + rhours + ":" + rminutes;
        // console.log(time_difference);
        return time_difference;
    } else {
        let time_difference = "-" + rhours + ":" + rminutes;
        // console.log(time_difference);
        return time_difference;
    }
};

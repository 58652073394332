import React from "react";
import './requests.scss'
import {checkAvailability, checkAvailabilityForTenderView, dateDiff} from "../../constants/commonFunc";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import routes from "../../constants/routes";
import moment from "moment";
import constants from "../../constants/constants";
import strings from "../../locals/string.json";

const request = strings.purchaseRequest;

class RequestItem extends React.Component {

    state = {
        updateRequestModal: false,
    }

    onView = (item) => {
        if (this.props.loggedInStatus) {
            if (item.availability) {
                this.props.history.push(routes.viewRequest + `/${item.tenderUrl}`, { item: item, currentStatus: this.props.currentStatus })
                // this.props.history.push(routes.viewRequest + `/${item.tenderUrl}`, item)
            } else {
                checkAvailabilityForTenderView(true)
            }
        } else {
            checkAvailabilityForTenderView()
        }
    }

    render() {
        const item = this.props.item;
        return <div className="tender-card single-feature style-two">
            <div className="custom-card details pointer">
                <div className="details-wrap">
                    {
                        this.props.loggedInStatus &&
                        <>
                            <i className={item.fav ? "fa fa-heart" : "fa fa-heart-o"} onClick={this.props.onFav} />
                            {
                                item.sellerFollowupStatus === 'WON' ?
                                    <i className='fa sellerFS fa-trophy' /> :
                                    item.sellerFollowupStatus === 'LOST' ?
                                        <i className='fa sellerFS fa-ban' /> : null
                            }


                        </>
                    }
                    <div onClick={() => this.onView(item)}>
                        <h6 className="tender-title mb-2 pr-4">{item.title}</h6>
                        {/*<p className="author">*/}
                        <span
                            className={'tender-subcategory'}>{item.subCategory.map((item, index) => index === 0 ? item.name : ", " + item.name)}</span>
                        {/*</p>*/}
                        <p className="sub-title readeal-top">{this.props.loggedInStatus ? item.company.name : request.loginToView}</p>
                        <ul className="info-list mb-2">
                            <li><i className="fa fa-map-marker" />{item.location !== null ? item.location : 'N/A'}</li>
                            {/*<li><b>Quantity</b>: {item.qty !== null ? item.qty : 'N/A'}</li>*/}
                            <li><b>Ref</b>: {item.id !== null ? item.id : 'N/A'}</li>
                            <li><b>Source</b>: {this.props.loggedInStatus ? item.source !== null ? item.source : 'N/A' : request.loginToView} </li>
                        </ul>

                        <div className="separator" />

                        {/*<ul className="contact-list">*/}
                        {/*    /!*<li className="date mt-2">*!/*/}
                        {/*    /!*    <b>Source</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {item.source !== null ? item.source : 'N/A'}</li>*!/*/}
                        {/*    /!*<li className="date mt-2">*!/*/}
                        {/*    /!*    <b>Published On</b>&nbsp;: {moment(item.publishedDate).format(constants.DATE_FORMAT_EXTEND)}*!/*/}
                        {/*    /!*</li>*!/*/}
                        {/*    /!*<li className="date mt-2">*!/*/}
                        {/*    /!*    <b>Closing On</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {moment(item.closingDate).format(constants.DATE_FORMAT_EXTEND)}*!/*/}
                        {/*    /!*</li>*!/*/}
                        {/*    <li className="remaining mt-2" style={{*/}
                        {/*        background: dateDiff(item) <= 0 ? "rgba(255, 110, 99, 0.15)" :*/}
                        {/*            dateDiff(item) > 0 && dateDiff(item) <= 5 ? "rgba(243, 141, 16, 0.15)" : "rgba(33, 170, 104, 0.15)",*/}
                        {/*        color: dateDiff(item) <= 0 ? "var(--main-color-two)" :*/}
                        {/*            dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)",*/}
                        {/*        borderColor: dateDiff(item) <= 0 ? "var(--main-color-two)" :*/}
                        {/*            dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)",*/}
                        {/*    }}>{*/}
                        {/*        dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 1 ? '1 Day Remaining' : dateDiff(item) + " Days Remaining"*/}
                        {/*    }*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        <div className={'row'}>
                            <div className={'req-card-dates-pub col-md-4'}>
                                <b>Published
                                    On</b> : {this.props.loggedInStatus ? moment.utc(item.publishedDate).local().format(constants.DATE_FORMAT_EXTEND) : request.loginToView} 
                            </div>
                            <div className={'req-card-dates-cls col-md-4'}>
                                <b>Closing
                                    On</b> : {moment.utc(item.closingDate).local().format(constants.DATE_FORMAT_EXTEND)}
                            </div>

                            <div className={'col-md-4'} align={'right'}>
                                <div className={`remaining-time-card`} style={{
                                    backgroundColor: this.props.currentStatus.action === "EXPIRED" ? "var(--main-color-two)" : dateDiff(item) < 0 ? "var(--main-color-two)" :
                                        dateDiff(item) === 0 || dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-one)"
                                }}>
                                    {
                                        this.props.currentStatus.action === "EXPIRED" ?
                                            <span className={'remaining-time-card-text'}>{request.expired}</span>
                                            :
                                            <span className={'remaining-time-card-text'}>{
                                                dateDiff(item) < 0 ? request.expired : dateDiff(item) === 0 ? request.lessThanADay : dateDiff(item) + 1 + request.daysRemaining
                                            }</span>
                                    }
                                    {/* <span className={'remaining-time-card-text'}>{
                                        dateDiff(item) < 0 ? request.expired : dateDiff(item) === 0 ? request.lessThanADay : dateDiff(item) + 1 + request.daysRemaining
                                    }</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(RequestItem));
